<template>
    <div class="sq-box" id="sqBox" ref="sqBox">
        <img src="@/assets/images/Group1.png" class="background-img" alt="" />
        <div class="scroll-top" :style="'background:rgba(246,230,196,' + scrollTop / 100 + ')'">
            <div class="sq-user">
                <div class="sq-user-left">
                    <img v-if="info.logo != ''" :src="info.logo" alt="" />
                </div>

                <!--            <img v-else class="sq-user-left" src="@/assets/images/LOGO.png" alt="" />-->
                <span></span>
                
                <div class="sq-user-right" v-if="token">
                    <img src="@/assets/images/Frame.png" alt="" />
                    <div class="sq-right-title">
						<el-select v-model="libraryValue" v-if="libraryList.length>0">
						    <el-option
						      v-for="(item, index) in libraryList"
						      :key="index"
						      :label="item.libName"
						      :value="item.id" @click.native ="libraryHandleSelect(index)">
						    </el-option>
						  </el-select>
						  <i class="el-icon-loading" v-else-if="loading" title="地理位置加载中"></i>
					<!-- {{ info.libName }} -->
					</div>
                    <el-input class="sq-user-search" prefix-icon="el-icon-search" placeholder="精彩活动查询" v-model="search" clearable @change="topSearch()"></el-input>
                    <img @click="$router.push({ path: '/my' })" class="head-portrait" v-if="userInfo.avatar" :src="userInfo.avatar" alt="" />
                    <img @click="$router.push({ path: '/my' })" class="head-portrait" v-else src="@/assets/images/mrtx.png" alt="" />
                    <div @click="$router.push({ path: '/my' })" class="sq-name">{{ userInfo.nickName }}</div>
                    <el-badge class="item" @click.native="$router.push({ path: 'News' })">
                        <img class="sq-message" src="@/assets/images/Frame-2.png" alt="" />
                    </el-badge>
                    <!-- <div class="btn" style="margin: 0 5px 0 10px" @click="logout">退出登录</div> -->
                </div>
                <div v-else class="sq-user-right">
                    <img src="@/assets/images/Frame.png" alt="" />
                    <div class="sq-right-title">
						<el-select v-model="libraryValue" v-if = "libraryList.length>0">
						    <el-option
						      v-for="(item, index) in libraryList"
						      :key="index"
						      :label="item.libName"
						      :value="item.id" @click.native ="libraryHandleSelect(index)">
						    </el-option>
						  </el-select>
						  <i class="el-icon-loading" v-else-if="loading" title="没有可用场馆"></i>
					</div>
                    <el-input class="sq-user-search" prefix-icon="el-icon-search" placeholder="精彩活动查询" v-model="search" clearable @change="topSearch()"></el-input>
                    <el-badge class="item" @click.native="$router.push({ path: 'News' })">
                        <img class="sq-message" src="@/assets/images/Frame-2.png" alt="" />
                    </el-badge>
                </div>
                <!-- <div v-else class="qing-login" @click="dialogVisible = true">请登录</div> -->
            </div>
        </div>
        <div class="sq-header">
            <el-menu :default-active="activePath" router class="el-menu-demo" background-color="#EDA200" active-text-color="#ffffff" text-color="#ffffff" mode="horizontal" @select="handleSelect">
                <el-menu-item index="/" class="sq-menu" route="/">
                    <img src="@/assets/images/Frame-3.png" alt="" />
                    首页
                </el-menu-item>
                <el-submenu index="2" class="sq-menu">
                    <template slot="title" class="sq-menu">
                        <img src="@/assets/images/Frame-4.png" alt="" />
                        活动
                    </template>
                    <el-menu-item index="excitingActivities" route="/excitingActivities">精彩活动</el-menu-item>
                    <el-menu-item index="venueReservation" route="/venueReservation">场馆预约</el-menu-item>
                    <el-menu-item index="courseTraining" route="/courseTraining">课程培训</el-menu-item>
                </el-submenu>
                <el-submenu index="3" class="sq-menu">
                    <template slot="title" class="sq-menu">
                        <img src="@/assets/images/Frame-5.png" alt="" />
                        发现
                    </template>
                    <el-menu-item index="moocLearning" route="/moocLearning">慕课学习</el-menu-item>
                    <el-menu-item index="civilizedCreation" route="/civilizedCreation">文明创作</el-menu-item>
                    <el-menu-item index="iWantToParticipate" route="/iWantToParticipate">我要参加</el-menu-item>
                </el-submenu>
                <el-menu-item route="/inCharge" index="inCharge" class="sq-menu">
                    <img src="@/assets/images/Frame-6.png" alt="" />
                    <span>总分馆</span>
                </el-menu-item>
                <el-submenu index="5" class="sq-menu">
                    <template slot="title" class="sq-menu">
                        <img src="@/assets/images/Frame-7.png" alt="" />
                        推荐资源
                    </template>
                    <el-menu-item route="/recommended/learnTalent" index="learnTalent">学才艺</el-menu-item>
                    <el-menu-item route="/recommended/duhaoshu" index="duhaoshu">读好书</el-menu-item>
                    <el-menu-item route="/recommended/enjoyActivities" index="enjoyActivities">享活动</el-menu-item>
                    <el-menu-item index="" route=""><a href="https://www.culturedc.cn" target="_blank">国家文化馆</a></el-menu-item>
                    <el-menu-item index="" route=""><a href="http://www.scc.org.cn" target="_blank">四川文化馆</a></el-menu-item>
                    <!-- <el-menu-item route="/"><a href="https://whg.ltzsgl.com" target="_blank">凉山州文化馆</a></el-menu-item> -->
                    <!--                    <el-menu-item route="/recommended" index="nonHeritage">看直播</el-menu-item>-->
                </el-submenu>
                <el-submenu index="6" class="sq-menu">
                    <template slot="title" class="sq-menu">
                        <img src="@/assets/images/Frame-8.png" alt="" />
                        非遗文化
                    </template>
                    <el-menu-item route="/nonHeritage" index="nonHeritage">非遗名录</el-menu-item>
                    <el-menu-item route="/nonHeritage/policy" index="nonHeritage">政策解读</el-menu-item>
                    <el-menu-item route="/nonHeritage/inherit" index="inherit">传承人介绍</el-menu-item>
                    <el-menu-item route="/nonHeritage/activity" index="activity">非遗活动</el-menu-item>
                    <el-menu-item :route="{ path: '/vrList', query: { type: '2' } }" index="vr">非遗VR</el-menu-item>
                </el-submenu>
                <!-- <el-menu-item route="/readingRoom" index="readingRoom" class="sq-menu">
                    <img src="@/assets/images/Frame-9.png" alt="" />
                    <span>阅览室</span>
                </el-menu-item> -->
                <el-submenu index="7" class="sq-menu">
                    <template slot="title" class="sq-menu">
                        <img src="@/assets/images/Frame-9.png" alt="" />
                        阅览室
                    </template>
                    <el-menu-item index="/readingRoom">看书</el-menu-item>
                    <!--                    <el-menu-item @click="window.open('http://www.baidu.com')">期刊</el-menu-item>-->
                    <!--                    <el-menu-item @click="window.open('http://www.baidu.com')">视频</el-menu-item>-->
                    <!--                    <el-menu-item @click="window.open('http://www.baidu.com')">听书</el-menu-item>-->
                    <!--                    <el-menu-item @click="window.open('http://www.baidu.com')">朗读音</el-menu-item>-->
                </el-submenu>
                <!-- <el-menu-item index="7" class="sq-menu">
                    <img src="@/assets/images/Frame-9.png" alt="" />
                    <span>阅览室</span>
                </el-menu-item> -->
                <el-menu-item route="/broadcast" index="broadcast" class="sq-menu">
                    <img src="@/assets/images/Frame-10.png" alt="" />
                    <span>看直播</span>
                </el-menu-item>
                <el-submenu index="" class="sq-menu">
                    <template slot="title" class="sq-menu">
                        <img src="@/assets/images/Frame-11.png" alt="" />
                        志愿服务
                    </template>
                    <el-menu-item index="volunteerIndex" route="/volunteerIndex">志愿者招募</el-menu-item>
                    <el-menu-item index="volunteerList" route="/volunteerList">志愿者风采</el-menu-item>
                </el-submenu>
                <el-menu-item route="/bank" index="bank" class="sq-menu">
                    <img src="@/assets/images/Frame-12.png" alt="" />
                    <span>文化银行</span>
                </el-menu-item>
                <el-menu-item route="/communityList" index="communityList" class="sq-menu">
                    <img src="@/assets/images/Frame-13.png" alt="" />
                    <span>社群</span>
                </el-menu-item>
                <el-submenu index="12" class="sq-menu">
                    <template slot="title" class="sq-menu">
                        <img src="@/assets/images/Frame-14.png" alt="" />
                        文化人才
                    </template>
                    <el-menu-item route="/culturalPersonnel" index="culturalPersonnel">文化人才</el-menu-item>
                    <el-menu-item route="/culturalFamily" index="culturalFamily">文化家族</el-menu-item>
                </el-submenu>
                <!--                <el-menu-item index="13" class="sq-menu" route=""></el-menu-item>-->
                <el-submenu index="13" class="sq-menu">
                    <template slot="title" class="sq-menu">
                        <img src="@/assets/images/Frame-15.png" alt="" />
                        关于本馆
                    </template>
                    <el-menu-item index="about" route="/about">本馆概况</el-menu-item>
                    <el-menu-item index="leader" route="/leader">领导班子</el-menu-item>
                    <el-menu-item index="organization" route="/organization">组织机构</el-menu-item>
                    <el-menu-item index="contact" route="/contact">联系我们</el-menu-item>
                </el-submenu>
            </el-menu>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="400px">
            <div class="login">
                <img v-if="info.logo != ''" class="logo" :src="info.logo" alt="" />
                <div class="line">
                    <div></div>
                    <span>登录</span>
                    <div></div>
                </div>
                <el-input class="phone" v-model="phone" placeholder="请输入手机号" />
                <div class="password">
                    <el-input v-model="password" type="text" placeholder="请输入验证码" />
                    <span @click="getCode" ref="code">获取验证码</span>
                </div>
                <div class="btn" @click="login">登录</div>
            </div>
        </el-dialog>
        <div class="sq-content" ref="sqContent">
            <router-view ref="excitingActivities" v-if="!reload"/>
        </div>
        <div class="footer" ref="sqfooter" id="sqFooterR">
            <myFooter />
        </div>
    </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
// import maps from 'qqmap';
import myFooter from '@/components/footer.vue';
import { getLibraryOtherInfoList, getLibraryPosition, getSendCode, login, logout } from '@/api';
export default {
    // import 引入的组件需要注入到对象中才能使用
    components: { myFooter },
    data() {
        // 这里存放数据
        return {
            activePath: '',
            search: '',
            dialogVisible: false,
            phone: '',
            password: '',
            token: '',
            time: null,
            scope: 60,
            longitude: 0, //经度
            latitude: 0, //维度
            city: '', //城市
            province: '', //省
            userInfo: {},
            info: {
                logo: '',
            },
            scrollTop: 0,
			libraryList: [],
			libraryValue: null,
			loading:false,
			reload:false
        };
    },
    props: {},
    // 方法集合
    methods: {
        getLibraryList() {
            getLibraryPosition({}).then((res) => {
                this.loading = false;
                this.info = res.data[0];
                localStorage.setItem('id', res.data[0].id);
                this.libraryList = res.data;
                this.libraryValue = this.info.id;
            });
        },
		topSearch(){
			if(this.$route.path.indexOf('/excitingActivities')>=0){
				this.$refs['excitingActivities'].topSearch(this.search);
			} else {
				this.$router.push({
					path: '/excitingActivities',
					query: {
						searchValue: this.search,
					},
				});
			}
		},
		async libraryHandleSelect(index) {
			this.info = this.libraryList[index];
			localStorage.setItem('id', this.info.id);
			this.reload = true;
			await this.$nextTick()
			this.reload = false;
		},
        handleSelect() {},
        getHeight() {
            let windowHeight = document.documentElement.clientHeight;
            let contentHeight = this.$refs.sqBox.clientHeight + 50;
            // console.log(windowHeight, contentHeight);
            if (windowHeight - contentHeight > 232) {
                this.$refs.sqfooter.id = 'sqFooterF';
            } else {
                this.$refs.sqfooter.id = 'sqFooterR';
            }
        },
        getPath() {
            let path = this.$route.path;
            let index = path.lastIndexOf('/');
            let activePath = path.substring(index + 1);
            // console.log(activePath);
            this.activePath = activePath || '/';
        },
        login() {
            if (this.phone == '') return this.$message.error('请输入手机号');
            if (this.password == '') return this.$message.error('请输入验证码');
            login({ phoneNumber: this.phone, code: this.password }).then((res) => {
                // console.log('用户登录：', res);
                if (res.code == 200) {
                    this.$message.success('登录成功');
                    this.dialogVisible = false;
                    this.token = res.data.token;
                    this.userInfo = res.data.userInfo;
                    localStorage.setItem('token', this.token);
                    localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo));
                    this.password = '';
                } else {
                    this.$message.error(res.msg);
                }
            });
            // this.dialogVisible = false;
            // this.$message.success('登录成功');
            // this.token = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6Ijc0MmUxMDkwLTAyZWYtNDRhNi1iMDk4LTYyZWJjNWQ1MGQzYSJ9.asaHSunwnBbdSfccI4AlpJ8tlwZgZOZ-IPovBg2DoEzNeZM9eiLSAAVDRiKR0s4WmOzhcgm8ny4IWtFlY_F7cA';
            // localStorage.setItem('token', this.token);
        },
        logout() {
            logout().then((res) => {
                if (res.code == 200) {
                    this.token = '';
                    this.userInfo = {};
                    localStorage.removeItem('token');
                    localStorage.removeItem('userInfo');
                    this.$router.push('/');
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getCode() {
            if (this.time != null) return;
            if (this.phone == '') return this.$message.error('请输入手机号');
            //判断是否是手机号
            if (!/^1[3456789]\d{9}$/.test(this.phone)) {
                return this.$message.error('请输入正确的手机号');
            }
            getSendCode(this.phone).then((res) => {
                if (res.code == 200) {
                    this.$message.success('验证码发送成功');
                    this.time = setInterval(() => {
                        this.scope--;
                        this.$refs.code.innerHTML = this.scope + 's';
                        if (this.scope == 0) {
                            this.$refs.code.innerHTML = '获取验证码';
                            this.scope = 60;
                            clearInterval(this.time);
                            this.time = null;
                        }
                    }, 1000);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getLocation() {
			this.loading = true;
            let geolocation = new window.qq.maps.Geolocation('WVRBZ-RMI6R-S46WU-WARXB-6V4WO-UWBIO', 'myapp');
            geolocation.getLocation(this.showPosition, this.errorPosition);
        },
        showPosition(position) {
            // console.log('position@@@@' + position);
            this.longitude = position.lng;
            this.latitude = position.lat;
            this.city = position.city;
            this.province = position.province;
            if (position.lng) {
                // console.log(position.lng, position.lat);
                localStorage.setItem('longitude', position.lng);
                localStorage.setItem('latitude', position.lat);
                getLibraryPosition({
                    longitude: this.longitude,
                    latitude: this.latitude,
                }).then((res) => {
					this.loading = false;
                    // console.log(res);
                    // if (res.data.length == 0) return;
                    this.info = res.data[0];
                    localStorage.setItem('id', res.data[0].id);
					this.libraryList = res.data;
					this.libraryValue = this.info.id;
                    // this.$router.go(0);
                });
            }
        },
        errorPosition() {
			this.loading = false;
            // console.log('定位失败');
            //继续定位
            this.getLocation();
        },
        getPosition() {
            //点击一次获取一次
            this.getLocation();
        },
        handleScroll(e) {
            // console.log(e);
            //获取页面滚动距离
            this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        },
    },
    // 监控 data 中的数据变化
    watch: {
        $route() {
            // console.log(1111);
            // this.$refs.sqfooter.id = 'sqFooterF';
            // setTimeout(() => {
            //     this.getHeight();
            // }, 500);
            this.getPath();
        },
        //  监听窗口滚动
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.getPath();
        this.token = localStorage.getItem('token');
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        // console.log(this.userInfo);
        window.addEventListener('scroll', this.handleScroll);
        // maps.init('ZKLBZ-7Q2K3-4Z5WJ-5ZQ4J-5ZQ4J-5ZQ4J');
        // localStorage.setItem('token', 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6Ijc0MmUxMDkwLTAyZWYtNDRhNi1iMDk4LTYyZWJjNWQ1MGQzYSJ9.asaHSunwnBbdSfccI4AlpJ8tlwZgZOZ-IPovBg2DoEzNeZM9eiLSAAVDRiKR0s4WmOzhcgm8ny4IWtFlY_F7cA');
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
        // 获取文化馆
        this.getLibraryList();
        // this.getHeight();
        // this.getLocation();
        //    窗口滚动距离
    },
};
</script>

<style lang="less" scoped>
.qing-login {
    color: #eda200;
}
.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    ::v-deep input {
        border: none;
        padding: 0 16px;
        height: 100%;
    }
    .logo {
        width: 39px;
        height: 39px;
        margin-bottom: 20px;
    }
    .line {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        span {
            margin: 0 24px;
        }
        div {
            flex: 1;
            border-top: 1px solid #ebebeb;
        }
    }
    .phone {
        width: 100%;
        height: 50px;
        margin-top: 35px;
        background: rgba(255, 255, 255, 0.01);
        border: 1px solid #ebebeb;
        border-radius: 2px;
        margin-bottom: 16px;
    }
    .password {
        border: 1px solid #ebebeb;
        border-radius: 2px;
        width: 100%;
        display: flex;
        align-items: center;
        padding-right: 12px;
        height: 50px;
        display: flex;
        margin-bottom: 40px;
        ::v-deep input {
            height: 100%;
            flex: 1;
        }
        span {
            //  不换行
            white-space: nowrap;
        }
        .active {
            color: #eda200;
        }
    }
    .btn {
        height: 50px;
        width: 100%;
        background: #eda200;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
    }
}
.footer {
    border-top: 1px solid #eda200;
    height: 232px;
    width: 100vw;
    background: #f6f6f6;
    margin-top: 50px;
}
#sqFooterF {
    position: fixed;
    bottom: 0;
    left: 0;
}
#sqFooterR {
    position: relative;
}
.sq-user-right {
    ::v-deep .el-input__inner {
        border-radius: 20px;
    }
}

.background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    background-repeat: no-repeat;
}
.scroll-top {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
}
.sq-user {
    display: flex;
    justify-content: space-between;
    //padding: 30px 0 10px 0;
    height: 80px;
    padding-top: 10px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    width: 1400px;

    //background: rgb(249, 230, 192);
    &-left {
        width: 53px;
        height: 53px;
        img {
            width: 100%;
            height: 100%;
            //border-radius: 50%;
        }
    }
    &-right {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
        img:nth-child(1) {
            width: 16px;
            height: 16px;
        }
        .sq-right-title {
            margin-left: 6px;
        }
        ::v-deep .el-input {
            width: 240px;
            margin: 0 16px;
        }
        .head-portrait {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
        .sq-name {
            margin: 0 18px 0 10px;
            font-weight: 700;
            font-size: 16px;
            color: #333333;
        }
        .sq-message {
            width: 24px !important;
            height: 24px !important;
        }
    }
}
.sq-box {
    //position: fixed;
    //width: 100%;
    //height: 100vh;
    left: 0;
    top: 0;
    right: 0;
    //display: flex;
    //flex-direction: column;

    padding-top: 180px;
    .sq-header {
        position: fixed;
        top: 80px;
        left: 0;
        right: 0;
        width: 1400px;
        margin: 0 auto;
        z-index: 999;
        ::v-deep .el-menu-demo {
            display: flex;
            justify-content: space-between;
            height: 80px;
            border: 0px;
            li {
                flex: 1 !important;
            }
        }

        ::v-deep .el-menu--horizontal > .el-menu-item {
            border-bottom: 0px !important;
            padding: 0 !important;
        }

        ::v-deep .el-submenu__title {
            border-bottom: 0px !important;
            background: rgba(0, 0, 0, 0) !important;
        }

        ::v-deep .el-submenu__icon-arrow {
            display: none;
        }

        ::v-deep .is-active,
        .is-active {
            background: #d69200 !important;
            background-color: #d69200 !important;
        }

        .el-menu-demo {
            width: 100%;
        }
    }
    .sq-header::after {
        position: absolute;
        top: 0;
        left: -150px;
        content: ' ';
        width: 150px;
        height: 80px;
        //  左右渐变
        background: linear-gradient(to right, rgba(0, 0, 0, 0), #eda200);
        right: 0;
    }
    .sq-header::before {
        position: absolute;
        top: 0;
        right: -150px;
        content: ' ';
        width: 150px;
        height: 80px;
        //  左右渐变
        background: linear-gradient(to left, rgba(0, 0, 0, 0), #eda200);
        //right: 0;
    }
    .sq-content {
        flex: 1;
        overflow-y: scroll;
        max-width: 1400px;
        min-width: 1400px;
        margin: 0 auto;
		min-height: calc(100vh - 232px - 160px);
        //overflow-x: hidden;
    }
    ::v-deep .sq-menu,
    ::v-deep .el-submenu__title {
        display: flex !important;
        //padding: 0 20px !important;
        align-items: center !important;
        height: 100% !important;
        flex: 1 !important;
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        line-height: normal !important;
        justify-content: center !important;

        img {
            width: 28px;
            height: 28px;
            margin-bottom: 5.5px;
        }
    }
}
</style>
